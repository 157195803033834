"use client";

import type { FC, PropsWithChildren } from "react";
import { useEffect, useState } from "react";

import Button from "../Button";
import CloseIcon from "../Icon/Close";
import type { Colors } from "../utils/colors";
import { getWindow } from "../utils/window";
import type { ModalProps } from "./Modal";
import Modal from "./Modal";

export type DialogProps = ModalProps & {
  backgroundColor?: Colors;
  wrapperClassName?: string;
  hideCloseButton?: boolean;
};

const Dialog: FC<PropsWithChildren<DialogProps>> = ({
  backgroundColor = "light",
  wrapperClassName = "",
  hideCloseButton = false,
  ...props
}) => {
  const [ready, isReady] = useState(false);
  const { open, children, onClose } = props;

  useEffect(() => {
    if (open) {
      getWindow().setTimeout(() => {
        isReady(true);
      }, 10);
    }

    return () => {
      isReady(false);
    };
  }, [open]);

  return (
    <Modal {...props} onClose={onClose}>
      <div
        className={[
          "relative",
          "flex",
          "w-full",
          "z-10",
          "shadow-md",
          "transition-all",
          "duration-300",
          `bg-${backgroundColor}`,
          ready ? "scale-100" : "scale-75",
          wrapperClassName,
        ].join(" ")}
      >
        {!hideCloseButton && (
          <Button
            variant="icon"
            className="absolute right-2 top-2 !h-fit w-fit rounded-full border"
            color="transparent"
            onClick={() => onClose?.()}
          >
            <CloseIcon width={8} color="neutral" />
          </Button>
        )}
        {children}
      </div>
    </Modal>
  );
};

export default Dialog;
