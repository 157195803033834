import type { LoadingButtonProps } from "@whitelabel-engine/ui/Button/LoadingButton";
import dynamic from "next/dynamic";
import type { ForwardedRef } from "react";
import { forwardRef, useMemo } from "react";

import { useDynamicConfigProvider } from "../DynamicConfigProvider";

const fetchLazyLoadedComponent = (name?: string) => {
  return dynamic(() => {
    switch (name) {
      case "@whitelabel-engine/ui/Button/LoadingButtonIrregular":
        return import("@whitelabel-engine/ui/Button/LoadingButtonIrregular");
      case "@whitelabel-engine/ui/Button/LoadingButtonRectangleNoBorder":
        return import(
          "@whitelabel-engine/ui/Button/LoadingButtonRectangleNoBorder"
        );
      case "@whitelabel-engine/ui/Button/LoadingButtonFlatRoundCorners":
        return import(
          "@whitelabel-engine/ui/Button/LoadingButtonFlatRoundCorners"
        );
      case "@whitelabel-engine/ui/Button/LoadingButtonIrregularAnimated":
        return import(
          "@whitelabel-engine/ui/Button/LoadingButtonIrregularAnimated"
        );
      default:
        return import("@whitelabel-engine/ui/Button/LoadingButton");
    }
  });
};

const DynamicButton = forwardRef(function DynamicButton(
  _props: LoadingButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { children, ...props } = _props;
  const features = useDynamicConfigProvider();
  const buttonModule = useMemo(
    () => features?.["@whitelabel-engine/ui/Button/LoadingButton"],
    [features],
  );

  const Component = useMemo(() => {
    return fetchLazyLoadedComponent(buttonModule as string);
  }, [buttonModule]);

  return (
    <Component {...props} ref={ref}>
      {children}
    </Component>
  );
});

export default DynamicButton;
