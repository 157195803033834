import type { FC } from "react";

import _BlankIcon from "./_Blank";
import type { IconProps } from "./utils";

const CloseIcon: FC<IconProps> = (props) => {
  return (
    <_BlankIcon viewBox="0 0 25 25" {...props}>
      <path d="M24.6339 22.8661C24.8684 23.1006 25.0001 23.4185 25.0001 23.75C25.0001 24.0816 24.8684 24.3995 24.6339 24.6339C24.3995 24.8684 24.0816 25.0001 23.75 25.0001C23.4185 25.0001 23.1006 24.8684 22.8661 24.6339L12.5 14.2677L2.13394 24.6339C1.89951 24.8684 1.58156 25.0001 1.25003 25.0001C0.918503 25.0001 0.600552 24.8684 0.366126 24.6339C0.131699 24.3995 6.53523e-09 24.0816 0 23.75C-6.53523e-09 23.4185 0.131699 23.1006 0.366126 22.8661L10.7324 12.5L0.366126 2.13394C0.131699 1.89951 -2.47008e-09 1.58156 0 1.25003C2.47008e-09 0.918503 0.131699 0.600552 0.366126 0.366126C0.600552 0.131699 0.918503 2.47008e-09 1.25003 0C1.58156 -2.47009e-09 1.89951 0.131699 2.13394 0.366126L12.5 10.7324L22.8661 0.366126C23.1006 0.131699 23.4185 -6.53523e-09 23.75 0C24.0816 6.53523e-09 24.3995 0.131699 24.6339 0.366126C24.8684 0.600552 25.0001 0.918503 25.0001 1.25003C25.0001 1.58156 24.8684 1.89951 24.6339 2.13394L14.2677 12.5L24.6339 22.8661Z" />
    </_BlankIcon>
  );
};

export default CloseIcon;
