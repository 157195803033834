import type { ForwardedRef } from "react";
import { forwardRef } from "react";

import LoadingIcon from "../Icon/Loading";
import type { IconProps } from "../Icon/utils";
import type { ButtonProps } from ".";
import ButtonIrregularAnimated from "./ButtonIrregularAnimated";

export type LoadingButtonIrregularAnimatedProps = ButtonProps & {
  loading?: boolean;
  iconProps?: IconProps;
};

const LoadingButtonIrregularAnimated = forwardRef(
  function LoadingButtonIrregular(
    {
      children,
      loading = false,
      disabled,
      iconProps = { color: "neutral" },
      ...props
    }: LoadingButtonIrregularAnimatedProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) {
    return (
      <ButtonIrregularAnimated
        ref={ref}
        {...props}
        disabled={loading || disabled}
      >
        {loading && <LoadingIcon {...iconProps} />}
        {children}
      </ButtonIrregularAnimated>
    );
  },
);

export default LoadingButtonIrregularAnimated;
