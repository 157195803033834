import type {
  ButtonHTMLAttributes,
  ForwardedRef,
  MouseEvent,
  ReactNode,
} from "react";
import { forwardRef, useCallback, useMemo } from "react";
import { tv } from "tailwind-variants";

import type { Colors } from "../utils/colors";

export type ButtonIrregularAnimatedProps =
  ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: "contained" | "outlined" | "ghost" | "text" | "icon";
    endIcon?: ReactNode | null;
    startIcon?: ReactNode | null;
    size?: "xsmall" | "small" | "medium" | "large";
    squared?: boolean;
    noShadow?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
    color?: Colors;
  };

const fontSize = {
  xsmall: "text-base",
  small: "text-base",
  medium: "text-lg",
  large: "text-xl",
};

const size = {
  xsmall: `px-4 py-1 ${fontSize.xsmall}`,
  small: `px-6 py-2 ${fontSize.small}`,
  medium: `px-12 py-4 ${fontSize.medium}`,
  large: `px-18 py-6 ${fontSize.large}`,
};

const baseButton = tv({
  base: [
    "border-solid",
    "duration-300",
    "ease-in-out",
    "ease-in-out",
    "flex",
    "font-semibold",
    "gap-4",
    "items-center",
    "justify-center",
    "relative",
    "rounded-none",
    "shadow-md",
    "z-[1]",
    "relative",
    "group",
  ],
  variants: {
    size: fontSize,
    disabled: {
      true: [
        "opacity-80",
        "hover:bg-unset",
        "hover:border-unset",
        "hover:text-unset",
        "cursor-not-allowed",
      ],
    },
    fullWidth: {
      true: "w-full",
    },
    squared: {
      true: ["rounded-none", "p-2", "h-full", "w-fit"],
    },
    leading: {
      true: "leading-6",
    },
  },
});

const button = {
  contained: tv({
    extend: baseButton,
    variants: {
      color: {
        primary: "bg-primary text-light",
        secondary: "bg-light text-primary",
        light: "bg-light text-primary",
        danger: "bg-danger text-light",
      },
      size,
    },
  }),
  outlined: tv({
    extend: baseButton,
    variants: {
      color: {
        primary:
          "text-primary hover:text-light hover:bg-primary bg-light border-primary",
        secondary:
          "text-light hover:text-secondary hover:bg-light bg-light border-light",
        light:
          "text-light hover:text-secondary hover:bg-light bg-light border-light",
        danger:
          "text-danger hover:text-light hover:bg-danger bg-light border-danger",
      },
      size,
    },
  }),
  ghost: tv({
    extend: baseButton,
    base: ["border-none", "bg-light"],
    variants: {
      color: {
        primary: "text-primary hover:shadow-xl",
        secondary: "text-secondary hover:shadow-xl",
        light: "text-light hover:shadow-xl",
        danger: "text-danger hover:shadow-xl",
      },
      size,
    },
  }),
  icon: tv({
    extend: baseButton,
    base: ["border-2", "p-2", "h-fit", "w-fit"],
    variants: {
      color: {
        primary:
          "border-primary bg-primary text-light hover:border-secondary hover:bg-secondary",
        secondary:
          "border-secondary bg-secondary text-light hover:border-primary hover:bg-primary",
        light:
          "border-neutral bg-light text-secondary hover:text-light hover:border-primary hover:bg-primary",
        danger:
          "border-danger bg-danger text-light hover:text-danger hover:border-danger hover:bg-light",
      },
      disabled: {
        false: "group",
      },
    },
  }),
  text: tv({
    extend: baseButton,
    base: ["p-0", "shadow-none"],
  }),
};

const wings = {
  left: tv({
    base: "absolute z-0 h-[100%] w-[100%] duration-150 ease-in group-hover:translate-x-0 group-hover:translate-y-0",
    variants: {
      color: {
        primary: "bg-[#5fbfdf]",
        secondary: "bg-[#5fbfdf]",
        light: "bg-[#5fbfdf]",
        danger: "bg-[#5fbfdf]",
      },
      disabled: {
        true: "opacity-30",
      },
      size: {
        xsmall: "translate-x-[-5px] translate-y-[-5px]",
        small: "translate-x-[-5px] translate-y-[-5px]",
        medium: "translate-x-[-10px] translate-y-[-10px]",
        large: "translate-x-[-10px] translate-y-[-10px]",
      },
    },
  }),
  right: tv({
    base: "absolute top-0 z-0 h-[100%] w-[100%] transition-all duration-150 ease-in group-hover:translate-x-0 group-hover:translate-y-0 group-hover:!shadow-none",
    variants: {
      color: {
        primary: "bg-[#ff00ff]",
        secondary: "bg-[#091DF5]",
        light: "bg-[#ff00ff]",
        danger: "bg-[#ff00ff]",
      },
      disabled: {
        true: "opacity-30",
      },
      size: {
        xsmall: "translate-x-[5px] translate-y-[5px]",
        small: "translate-x-[5px] translate-y-[5px]",
        medium: "translate-x-[10px] translate-y-[10px]",
        large: "translate-x-[10px] translate-y-[10px]",
      },
    },
  }),
};

export const ButtonIrregularAnimated = forwardRef(
  function ButtonIrregularAnimated(
    {
      children,
      className = "",
      color = "primary",
      disabled = false,
      endIcon = null,
      fullWidth = false,
      size = "small",
      squared = false,
      startIcon = null,
      type = "button",
      variant = "contained",
      noShadow: _noShadow,
      ...props
    }: ButtonIrregularAnimatedProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) {
    const buttonClassNames = useMemo(() => {
      if (variant === "text") {
        return button.text({
          className: `text-${color} ${className}`,
          disabled,
          size,
          leading: true,
        });
      }

      return button[variant!]({
        // @ts-expect-error
        color,
        size,
        className,
        fullWidth,
        squared,
        disabled,
        leading: true,
        contrast: variant !== "icon",
      });
    }, [variant, color, disabled, className, squared, fullWidth, size]);

    const wingsClassNames = useMemo(() => {
      if (variant === "text") {
        return {
          left: "",
          right: "",
        };
      }
      return {
        // @ts-expect-error
        left: wings.left({ color, size, disabled }),
        // @ts-expect-error
        right: wings.right({ color, size, disabled }),
      };
    }, [color, size, disabled]);

    const shadowAccordion = useMemo(() => {
      const shadowRepetition = ["medium", "large"].includes(size) ? 10 : 5;
      return [...Array(shadowRepetition).fill(0)]
        .map((_, index) => `-${index}px -${index}px 0px 0 #ff00ff`)
        .join(",");
    }, [size]);

    const handleOnClick = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        if (disabled) return;
        props?.onClick?.(event);
      },
      [disabled, props?.onClick],
    );

    return (
      <button
        ref={ref}
        disabled={disabled}
        className={`group relative h-fit ${className}`}
        type={type}
        {...props}
        onClick={props?.onClick ? handleOnClick : undefined}
      >
        <div className={wingsClassNames.left} />
        <div className={buttonClassNames}>
          {startIcon}
          {children}
          {endIcon}
        </div>
        <div
          className={wingsClassNames.right}
          style={{
            boxShadow: shadowAccordion,
          }}
        />
      </button>
    );
  },
);

export default ButtonIrregularAnimated;
