"use client";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { t } from "@whitelabel-engine/i18n";
import LoadingButtonIrregularAnimated, {
  type LoadingButtonIrregularAnimatedProps,
} from "@whitelabel-engine/ui/Button/LoadingButtonIrregularAnimated";
import Text from "@whitelabel-engine/ui/Text";
import type {
  FontTransform,
  FontWeight,
} from "@whitelabel-engine/ui/utils/fonts";
import { preventStopPropagation } from "@whitelabel-engine/ui/utils/prevent";
import {
  Children,
  cloneElement,
  type FC,
  type MouseEvent,
  type PropsWithChildren,
} from "react";

import IconRender from "../IconRender";

type ConnectWalletButtonProps = {
  variant?: LoadingButtonIrregularAnimatedProps["variant"];
  color?: LoadingButtonIrregularAnimatedProps["color"];
  colorError?: LoadingButtonIrregularAnimatedProps["color"];
  colorIcon?: LoadingButtonIrregularAnimatedProps["color"];
  colorIconSelected?: LoadingButtonIrregularAnimatedProps["color"];
  fullWidth?: boolean;
  longMobileText?: boolean;
  showIcon?: boolean;
  showChildrenWithWrongNetwork?: boolean;
  weight?: FontWeight;
  transform?: FontTransform;
  "data-testid"?: string;
  hideMobileIcon?: boolean;
};

const ConnectWalletButton: FC<PropsWithChildren<ConnectWalletButtonProps>> = ({
  variant,
  color,
  colorIcon = "light",
  colorIconSelected,
  fullWidth = false,
  longMobileText = false,
  showIcon = false,
  showChildrenWithWrongNetwork = false,
  weight,
  transform = "uppercase",
  children,
  hideMobileIcon = false,
  ...props
}) => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const loading = authenticationStatus === "loading";
        const ready = mounted && !loading;
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");

        if (!connected) {
          return (
            <>
              <LoadingButtonIrregularAnimated
                className="hidden md:block"
                fullWidth={fullWidth}
                variant={variant}
                color={color}
                data-testid={props?.["data-testid"]}
                onClick={(event: MouseEvent<HTMLButtonElement>) => {
                  preventStopPropagation<HTMLButtonElement>(event);
                  openConnectModal();
                }}
              >
                {showIcon && (
                  <IconRender
                    color={colorIcon ?? color}
                    hoverColor={colorIconSelected ?? color}
                    icon="WalletAdd"
                    width={18}
                  />
                )}
                <Text
                  transform={transform}
                  color="primary"
                  weight={weight}
                >{t`Connect Wallet`}</Text>
              </LoadingButtonIrregularAnimated>

              <LoadingButtonIrregularAnimated
                className="block md:hidden"
                fullWidth={fullWidth}
                variant={variant}
                color={color}
                size="small"
                data-testid={props?.["data-testid"]}
                onClick={(event: MouseEvent<HTMLButtonElement>) => {
                  preventStopPropagation<HTMLButtonElement>(event);
                  openConnectModal();
                }}
              >
                {showIcon && (
                  <IconRender
                    color={colorIcon ?? color}
                    hoverColor={colorIconSelected ?? color}
                    icon="WalletAdd"
                    width={18}
                  />
                )}
                <Text
                  transform={transform}
                  color="primary"
                  weight={weight}
                >{t`Connect Wallet`}</Text>
              </LoadingButtonIrregularAnimated>
            </>
          );
        }

        if (chain.unsupported) {
          return showChildrenWithWrongNetwork ? (
            Children.map(children as any[], (child: any) => {
              return cloneElement(child, {
                ...child?.props,
                disabled: true,
              });
            })
          ) : (
            <LoadingButtonIrregularAnimated
              fullWidth={fullWidth}
              variant={variant}
              color={color}
              onClick={openChainModal}
              data-testid={props?.["data-testid"]}
            >
              <Text
                transform={transform}
                color="primary"
                weight={weight}
              >{t`Wrong Network`}</Text>
            </LoadingButtonIrregularAnimated>
          );
        }

        if (loading) {
          return (
            <LoadingButtonIrregularAnimated fullWidth={fullWidth} loading>
              <Text
                transform={transform}
                weight={weight}
                className="hidden md:block"
              >{t`Loading...`}</Text>
            </LoadingButtonIrregularAnimated>
          );
        }

        return (
          children || (
            <>
              <LoadingButtonIrregularAnimated
                fullWidth={fullWidth}
                className="hidden md:block"
                variant={variant}
                color={color}
                onClick={openAccountModal}
                data-testid={props?.["data-testid"]}
              >
                <Text transform={transform} weight={weight}>
                  {account.displayName}
                  {account.displayBalance ? ` (${account.displayBalance})` : ""}
                </Text>
              </LoadingButtonIrregularAnimated>

              <LoadingButtonIrregularAnimated
                className="block md:hidden"
                size="small"
                fullWidth={fullWidth}
                variant={variant}
                color={color}
                onClick={openAccountModal}
                data-testid={props?.["data-testid"]}
              >
                {!hideMobileIcon && (
                  <IconRender
                    icon="WalletAdd"
                    color={colorIcon ?? color}
                    hoverColor={colorIconSelected ?? color}
                    width={20}
                  />
                )}
                {longMobileText ? (
                  <Text transform={transform} weight={weight}>
                    {account.displayName}
                    {account.displayBalance
                      ? ` (${account.displayBalance})`
                      : ""}
                  </Text>
                ) : null}
              </LoadingButtonIrregularAnimated>
            </>
          )
        );
      }}
    </ConnectButton.Custom>
  );
};

export default ConnectWalletButton;
